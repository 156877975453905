/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="startExport()"
          >Exportar facturas</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field>
          <b-field label="Almacenes" expanded label-position="inside">
            <b-taginput
              :data="warehouses"
              autocomplete
              :allow-new="false"
              :open-on-focus="true"
              field="NOMBRE_ALM"
              icon="warehouse"
              v-model="filterWarehouses"
              @add="(value) => changeFilterValue()"
              @remove="(value) => changeFilterValue()"
              ellipsis
              placeholder="Nombre de almacen"
              aria-close-label="Borrar almacen seleccionado"
              check-infinite-scroll
            >
            </b-taginput>
          </b-field>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="global-list-main-container">
      <div class="table-header">
        Cantidad de ventas: <b>{{ sales.length }}</b>
      </div>
      <b-table
        pagination-position="both"
        :data="sales"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        scrollable
        ref="sales"
        :checked-rows.sync="checkedSales"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.CLAVE_VENTA}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        default-sort="CLAVE_VENTA"
      >
        <template #empty>
          <div class="has-text-centered">No hay ventas</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :key="column.field"
            :visible="column.display"
            :label="column.label"
            :custom-sort="column.customSort"
            :custom-search="column.customSearch"
          >
            <span v-if="!checkIfValid(props.row[column.field])">{{
              "Sin información"
            }}</span>
            <span
              v-if="column.money && checkIfValid(props.row[column.field])"
              >{{
                props.row[column.field] | money("MXN", column.decimals | 2)
              }}</span
            >
            <span
              v-if="!column.money && checkIfValid(props.row[column.field])"
              >{{ props.row[column.field] }}</span
            >
          </b-table-column>
        </template>

        <template slot="detail">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="media-content">
              <div class="content">
                <p>No hay información que mostrar</p>
              </div>
            </div>
          </article>
        </template>

        <template #footer>
          <div class="has-text-right">
            Total en ventas con pago sin facturar:
            {{ getTableTotalSales() | money("MXN", 14) }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";
import { openConfirmationModalDanger } from "@/utils/component-fns";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "SalesXMLDifferences",
  components: {},
  props: ["type"],
  directives: { cleave },
  data() {
    return {
      perPage: 25,
      checkedSales: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "CLAVE_VENTA",
          label: "Folio",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "statusFormatted",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "statusDeliveryFormatted",
          label: "Estatus de envio",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "USUARIO_CREACION",
          label: "Usuario creación",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "USUARIO_CONFIRMACION",
          label: "Usuario confirmación",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "advancedPaymentFormatted",
          label: "Anticipos",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "advancedPaymentCreditNote",
          label: "Amortizada",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "FACTURA_FOR",
          label: "Facturas",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "NOMBRE_CLIENTE",
          label: "Cliente",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALM",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_VENDEDOR",
          label: "Vendedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_ALMACEN_ORIGEN",
          label: "Origen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha de la venta",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "RESTA_FOR",
          label: "Restante a pagar en cuenta por cobrar",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
          decimals: 2,
          customSort: this.sortyByCXCTotal,
        },
        {
          field: "CUENTA_STATUS_FOR",
          label: "Estatus de cuenta por cobrar",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "SUBTOTAL",
          label: "Subtotal",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
          decimals: 2,
        },
        {
          field: "IVA",
          label: "Iva total",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
          decimals: 2,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          decimals: 2,
          customSort: this.sortyByTotal,
        },
        {
          field: "RESTA_ENV_FOR",
          label: "Restante de envio",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
          decimals: 2,
        },
        {
          field: "FACTURAS_DIF",
          label: "Diferencia entre total y xml",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          decimals: 14,
          customSort: this.sortByDifference,
        },
      ],
      filterWarehouses: [],
      masks: Masks,
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {
    this.filterWarehouses = [];
    if (this.userInformation.ALMACENES.length > 0) {
      for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
        for (const singleWarehouse of this.warehouses) {
          if (singleSelectedWarehouse === singleWarehouse._id) {
            this.filterWarehouses.push(singleWarehouse);
          }
        }
      }
    }
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
      allPromises.push(
        this.$store.dispatch("GETSALES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
          warehouses: this.filterWarehouses.map((singleWarehouse) => {
            return singleWarehouse._id;
          }),
        })
      );
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPRICESLISTS"));
      allPromises.push(
        this.$store.dispatch("GETARTICLESPAGINATED", {
          skip: 0,
          limit: 50,
          saleActive: true,
        })
      );
      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));
      allPromises.push(this.$store.dispatch("GETDISCOUNTCONCEPTS"));
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETCOMMERCIALBUSINESS"));
      allPromises.push(this.$store.dispatch("GETVEHICULES"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación",
        message: `¿Desea confirmar la exportación de las diferencias a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: async () => {
          this.exportData();
        },
      });
    },
    exportData() {
      let payload = {
        data: this.sales.map((singleSale) => {
          const singleSaleFormatted = {};

          singleSaleFormatted["FOLIO"] = "";
          if (singleSale.CLAVE_VENTA) {
            singleSaleFormatted["FOLIO"] = singleSale.CLAVE_VENTA;
          }

          singleSaleFormatted["CLIENTE"] = "";
          if (singleSale.NOMBRE) {
            singleSaleFormatted["CLIENTE"] = singleSale.NOMBRE;
          }

          singleSaleFormatted["ALMACEN"] = "";
          if (singleSale.NOMBRE_ALMACEN_ORIGEN) {
            singleSaleFormatted["ALMACEN"] = singleSale.NOMBRE_ALMACEN_ORIGEN;
          }

          singleSaleFormatted["FACTURAS"] = "";
          if (singleSale.FACTURA_FOR) {
            singleSaleFormatted["FACTURAS"] = singleSale.FACTURA_FOR;
          }

          singleSaleFormatted["TOTAL"] = 0;
          singleSaleFormatted["TOTAL"] = singleSale.TOTAL;

          singleSaleFormatted["RESTANTE A PAGAR"] = 0;
          singleSaleFormatted["RESTANTE A PAGAR"] = singleSale.RESTA_FOR;

          singleSaleFormatted["FECHA DE CREACION"] = "";
          if (singleSale.formatedCreationDate) {
            singleSaleFormatted["FECHA DE CREACION"] =
              singleSale.formatedCreationDate;
          }

          singleSaleFormatted["DIFERENCIA XML"] = 0;
          singleSaleFormatted["DIFERENCIA XML"] = singleSale.FACTURAS_DIF;

          return singleSaleFormatted;
        }),
        name: "diferencias-xml-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    async changeFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETSALES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
            warehouses: this.filterWarehouses.map((singleWarehouse) => {
              return singleWarehouse._id;
            }),
          })
        );

        await Promise.all(allPromises);
      }
    },
    sortyByTotal(a, b, isAsc) {
      if (isAsc) {
        return a.TOTAL.localeCompare(b.TOTAL, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.TOTAL.localeCompare(a.TOTAL, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
    sortByDifference(a, b, isAsc) {
      if (isAsc) {
        return Number(a.FACTURAS_DIF) - Number(b.FACTURAS_DIF);
      } else {
        return Number(b.FACTURAS_DIF) - Number(a.FACTURAS_DIF);
      }
    },
    sortyByCXCTotal(a, b, isAsc) {
      if (isAsc) {
        return String(a.RESTA_FOR).localeCompare(
          String(b.RESTA_FOR),
          undefined,
          {
            numeric: true,
            sensitivity: "base",
          }
        );
      } else {
        return String(b.RESTA_FOR).localeCompare(
          String(a.RESTA_FOR),
          undefined,
          {
            numeric: true,
            sensitivity: "base",
          }
        );
      }
    },

    getTableTotalSales() {
      let totalSales = 0;

      for (const singleSale of this.sales) {
        if (singleSale.FACTURAS_DIF) {
          totalSales += Number(singleSale.FACTURAS_DIF);
        }
      }

      return totalSales;
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
    warehouses() {
      this.filterWarehouses = [];
      if (this.userInformation.ALMACENES.length > 0) {
        for (const singleSelectedWarehouse of this.userInformation.ALMACENES) {
          for (const singleWarehouse of this.warehouses) {
            if (singleSelectedWarehouse === singleWarehouse._id) {
              this.filterWarehouses.push(singleWarehouse);
            }
          }
        }
      }
    },
  },
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    sales() {
      return this.$store.getters.SALESXMLDIFFERENCES.map((singleSale) => {
        const saleWithProps = { ...singleSale };

        saleWithProps.TOTAL = String(singleSale.TOTAL);

        saleWithProps.posFormatted = singleSale.POS === "S" ? "Sí" : "No";

        if (singleSale.CLAVE_ALMACEN) {
          saleWithProps.NOMBRE_ALM = singleSale.CLAVE_ALMACEN.NOMBRE_ALM;
        }

        if (singleSale.CLAVE_CLIENTE) {
          saleWithProps.NOMBRE_CLIENTE =
            singleSale.CLAVE_CLIENTE.NOMBRE_EMPRESA;
        }

        saleWithProps.formatedCreationDate = moment(singleSale.FECHA).format(
          "DD-MM-YYYY HH:mm"
        );

        saleWithProps.advancedPaymentFormatted = "";
        if (saleWithProps.ANTICIPO_INFO) {
          saleWithProps.advancedPaymentFormatted =
            saleWithProps.ANTICIPO_INFO.NUM_FOLIO;
        }

        saleWithProps.advancedPaymentCreditNote = "No";
        if (saleWithProps.FACTURAS) {
          for (const singleInvoice of saleWithProps.FACTURAS) {
            if (singleInvoice.NOTAC) {
              for (const singleCreditNote of singleInvoice.NOTAC) {
                if (singleCreditNote.TIPO === "A") {
                  saleWithProps.advancedPaymentCreditNote = "Si";
                }
              }
            }
          }
        }

        saleWithProps.FACTURA_FOR = "";
        if (singleSale.FACTURAS) {
          saleWithProps.FACTURA_FOR = singleSale.FACTURAS.map(
            (singleInvoice) => {
              return singleInvoice.CLAVE_FACTURA;
            }
          ).toString();
        }

        if (saleWithProps.STATUS === "AC") {
          saleWithProps.statusFormatted = "Activa";
        } else if (saleWithProps.STATUS === "CA") {
          saleWithProps.statusFormatted = "Cancelada";
          saleWithProps.RESTA = 0;
        } else if (saleWithProps.STATUS === "AN") {
          saleWithProps.statusFormatted = "Activa Nota Crédito";
        }

        if (!saleWithProps.STATUS_ENV || saleWithProps.STATUS_ENV === "P") {
          saleWithProps.statusDeliveryFormatted = "Pendiente";
        } else if (saleWithProps.STATUS_ENV === "I") {
          saleWithProps.statusDeliveryFormatted = "Incompleto";
        } else if (saleWithProps.STATUS_ENV === "C") {
          saleWithProps.statusDeliveryFormatted = "Completo";
        }

        saleWithProps.RESTA_FOR = 0;
        if (saleWithProps.CUENTAS_COB) {
          for (const singleIncome of saleWithProps.CUENTAS_COB) {
            saleWithProps.RESTA_FOR += singleIncome.RESTA;
          }
          saleWithProps.RESTA_FOR =
            saleWithProps.RESTA_FOR > 0 ? String(saleWithProps.RESTA_FOR) : 0;
        }

        saleWithProps.FACTURAS_DIF = 0;
        if (singleSale.FACTURAS_DIF) {
          saleWithProps.FACTURAS_DIF = String(singleSale.FACTURAS_DIF);
        }

        saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
        if (saleWithProps.CUENTAS_COB) {
          for (const singleIncome of saleWithProps.CUENTAS_COB) {
            if (singleIncome.STATUS === "AC")
              saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
            if (singleIncome.STATUS === "PE")
              saleWithProps.CUENTA_STATUS_FOR = "Pendiente de pagar";
            if (singleIncome.STATUS === "PA")
              saleWithProps.CUENTA_STATUS_FOR = "Totalmente pagada";
            if (singleIncome.STATUS === "CA") {
              saleWithProps.CUENTA_STATUS_FOR = "Cancelada";
              saleWithProps.RESTA_FOR = 0;
            }
            if (singleIncome.STATUS === "VE") {
              saleWithProps.CUENTA_STATUS_FOR = "Vencida";
            }
          }
        }

        saleWithProps.RESTA_ENV_FOR =
          saleWithProps.RESTA > 0 ? saleWithProps.RESTA : 0;

        return saleWithProps;
      });
    },
    articles() {
      return this.$store.getters.ARTICLES.map((singleArticle) => {
        let articleWithProps = { ...singleArticle };
        return articleWithProps;
      });
    },
    clients() {
      return this.$store.getters.CLIENTS;
    },
    priceLists() {
      return this.$store.getters.PRICES_LISTS.map((singlePriceList) => {
        let singlePriceListWithProps = { ...singlePriceList };
        return singlePriceListWithProps;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
    userInformation() {
      return this.$store.getters.USER;
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
