/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="startExport()"
          >Exportar facturas</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field label="Filtrar por clientes" label-position="inside">
          <b-select
            placeholder="Filtrar por clientes"
            icon="account-group-outline"
            v-model="filterClient"
            class="global-lists-filters-control"
          >
            <option value="">Todos los clientes</option>
            <option v-for="client in clients" :value="client" :key="client._id">
              {{ client ? client.NOMBRE_EMPRESA : "" }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de clientes
      <b>{{ filteredAccounts.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="filteredAccounts"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedIncomes"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.NUM_FOLIO}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">No hay clientes</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
            :cell-class="
              props.row.DIAS_VEN > 0 && props.row.STATUS !== 'PA'
                ? 'red-cell'
                : ''
            "
          >
            <span
              v-if="!checkIfValid(props.row[column.field]) && !column.money"
              >{{ "Sin información" }}</span
            >
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <div class="content">
                <p>No hay información que mostrar</p>
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import { openConfirmationModalDanger } from "@/utils/component-fns";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ClientsAccountsInvoices",
  props: [],
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedIncomes: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "NUM_FOLIO",
          label: "Folio de cuenta por cobrar",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CLIENTE_FOR",
          label: "Cliente",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ALMACEN_FOR",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "NOMBRE_VENDEDOR_FOR",
          label: "Vendedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION",
          label: "Descripcion",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FACTURAS_FOR",
          label: "Facturas",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "posFormatted",
          label: "Punto de venta",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "NO_DOCUM",
          label: "Número de documento",
          sortable: true,
          searchable: true,
          display: false,
        },

        {
          field: "STATUS_FORMATED",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FORMA_P_FORMATED",
          label: "Forma de pago de venta",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FORMAS_P_FORMATED",
          label: "Formas de pago usadas",
          sortable: true,
          searchable: true,
          display: false,
        },

        {
          field: "IMPORTE_E",
          label: "Sub total",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "DESC_GTO",
          label: "Descuentos",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "COST_GTO",
          label: "Indirectos",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },

        {
          field: "IVA_GTO",
          label: "Total IVA",
          sortable: true,
          searchable: true,
          display: false,
          money: true,
        },
        {
          field: "TOTAL_GTO",
          label: "Total a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "RESTA",
          label: "Restante a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByMisingQuantity,
        },
        {
          field: "PERI",
          label: "Plazo en días",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha de creación",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "formatedExpirationDate",
          label: "Fecha de vencimiento",
          sortable: true,
          searchable: true,
          display: false,
          customSort: this.sortByDateExpiration,
        },
        {
          field: "DIAS_VEN",
          label: "Días vencida",
          sortable: true,
          searchable: true,
          display: false,
        },
      ],
      masks: Masks,
      filterClient: "",
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      allPromises.push(
        this.$store.dispatch("GETINCOMES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(
        this.$store.dispatch("GETEXPENSES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByDateExpiration(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_VEN).getTime() || -Infinity) -
          (new Date(a.FECHA_VEN).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_VEN).getTime() || -Infinity) -
          (new Date(b.FECHA_VEN).getTime() || -Infinity)
        );
      }
    },
    sortByMisingQuantity(a, b, isAsc) {
      if (isAsc) {
        return Number(a.RESTA) - Number(b.RESTA);
      } else {
        return Number(b.RESTA) - Number(a.RESTA);
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación",
        message: `¿Desea confirmar la exportación de los estados de cuenta a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: async () => {
          this.exportData();
        },
      });
    },
    exportData() {
      let payload = {
        data: this.filteredAccounts.map((singleInvoice) => {
          const singleInvoiceFormatted = {};

          singleInvoiceFormatted["FOLIO"] = "";
          if (singleInvoice.NUM_FOLIO) {
            singleInvoiceFormatted["FOLIO"] = singleInvoice.NUM_FOLIO;
          }

          singleInvoiceFormatted["CLIENTE"] = "";
          if (singleInvoice.NOMBRE) {
            singleInvoiceFormatted["CLIENTE"] = singleInvoice.NOMBRE;
          }

          singleInvoiceFormatted["DESCRIPCION"] = "";
          if (singleInvoice.DESCRIPCION) {
            singleInvoiceFormatted["DESCRIPCION"] = singleInvoice.DESCRIPCION;
          }

          singleInvoiceFormatted["FACTURAS"] = "";
          if (singleInvoice.FACTURAS_FOR) {
            singleInvoiceFormatted["FACTURAS"] = singleInvoice.FACTURAS_FOR;
          }

          singleInvoiceFormatted["ESTATUS"] = "";
          if (singleInvoice.STATUS_FORMATED) {
            singleInvoiceFormatted["ESTATUS"] = singleInvoice.STATUS_FORMATED;
          }

          singleInvoiceFormatted["FORMA DE PAGO DE VENTA"] = "";
          if (singleInvoice.FORMA_P_FORMATED) {
            singleInvoiceFormatted["FORMA DE PAGO DE VENTA"] =
              singleInvoice.FORMA_P_FORMATED;
          }

          singleInvoiceFormatted["TOTAL A PAGAR"] = "";
          if (singleInvoice.TOTAL_GTO) {
            singleInvoiceFormatted["TOTAL A PAGAR"] = singleInvoice.TOTAL_GTO;
          }

          singleInvoiceFormatted["RESTANTE A PAGAR"] = 0;
          singleInvoiceFormatted["RESTANTE A PAGAR"] =
            singleInvoice.RESTA_TOTAL;

          singleInvoiceFormatted["FECHA DE CREACION"] = "";
          if (singleInvoice.formatedCreationDate) {
            singleInvoiceFormatted["FECHA DE CREACION"] =
              singleInvoice.formatedCreationDate;
          }

          return singleInvoiceFormatted;
        }),
        name: "facturas-clientes-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };

        return clientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
    clientsAccounts() {
      return this.$store.getters.INCOMES.map((singleIncome) => {
        let singleIncomeWithProps = { ...singleIncome };

        singleIncomeWithProps.IVA_GTO = String(singleIncomeWithProps.IVA_GTO);
        singleIncomeWithProps.TOTAL_GTO = String(
          singleIncomeWithProps.TOTAL_GTO
        );

        singleIncomeWithProps.RESTA = String(singleIncomeWithProps.RESTA);

        singleIncomeWithProps.FORMA_P_FORMATED = "";
        if (singleIncome.CLAVE_VENTA && singleIncome.CLAVE_VENTA.FORMA_P) {
          singleIncomeWithProps.FORMA_P_FORMATED =
            singleIncome.CLAVE_VENTA.FORMA_P.DESCRIBE_P;
        }

        if (
          singleIncome.CLAVE_VENTA_SERVICIO &&
          singleIncome.CLAVE_VENTA_SERVICIO.FORMA_P
        ) {
          singleIncomeWithProps.FORMA_P_FORMATED =
            singleIncome.CLAVE_VENTA_SERVICIO.FORMA_P.DESCRIBE_P;
        }

        singleIncomeWithProps.FORMAS_P_FORMATED = "";
        if (singleIncome.CLAVE_VENTA && singleIncome.CLAVE_VENTA.FORMA_P) {
          singleIncomeWithProps.FORMAS_P_FORMATED += `${singleIncome.CLAVE_VENTA.FORMA_P.DESCRIBE_P} `;
        }

        if (singleIncomeWithProps.PAGOS) {
          for (const singlePayment of singleIncomeWithProps.PAGOS) {
            if (
              !singleIncomeWithProps.FORMAS_P_FORMATED.includes(
                singlePayment.FORMA_P.DESCRIBE_P
              )
            ) {
              singleIncomeWithProps.FORMAS_P_FORMATED += `${singlePayment.FORMA_P.DESCRIBE_P} `;
            }
          }
        }

        if (singleIncome.CLAVE_VENTA) {
          singleIncomeWithProps.posFormatted =
            singleIncome.CLAVE_VENTA.POS === "S" ? "Sí" : "No";
        }

        singleIncomeWithProps.NOMBRE_VENDEDOR_FOR = "";
        if (
          singleIncome.CLAVE_VENTA &&
          singleIncome.CLAVE_VENTA.NOMBRE_VENDEDOR
        ) {
          singleIncomeWithProps.NOMBRE_VENDEDOR_FOR =
            singleIncome.CLAVE_VENTA.NOMBRE_VENDEDOR;
        }

        if (
          singleIncome.CLAVE_VENTA_SERVICIO &&
          singleIncome.CLAVE_VENTA_SERVICIO.NOMBRE_VENDEDOR
        ) {
          singleIncomeWithProps.NOMBRE_VENDEDOR_FOR =
            singleIncome.CLAVE_VENTA_SERVICIO.NOMBRE_VENDEDOR;
        }

        singleIncomeWithProps.FACTURAS_FOR = "";
        if (
          singleIncomeWithProps.CLAVE_VENTA &&
          singleIncomeWithProps.CLAVE_VENTA.FACTURAS &&
          singleIncomeWithProps.CLAVE_VENTA.FACTURAS.length > 0
        ) {
          for (const singleInvoice of singleIncomeWithProps.CLAVE_VENTA
            .FACTURAS) {
            singleIncomeWithProps.FACTURAS_FOR += `${singleInvoice.CLAVE_FACTURA} `;
          }
        }

        if (singleIncomeWithProps.STATUS === "AC")
          singleIncomeWithProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleIncomeWithProps.STATUS === "PE")
          singleIncomeWithProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleIncomeWithProps.STATUS === "PA")
          singleIncomeWithProps.STATUS_FORMATED = "Totalmente pagada";
        if (singleIncomeWithProps.STATUS === "CA") {
          singleIncomeWithProps.STATUS_FORMATED = "Cancelada";
          singleIncomeWithProps.RESTA = 0;
        }
        if (singleIncomeWithProps.STATUS === "VE") {
          singleIncomeWithProps.STATUS_FORMATED = "Vencida";
        }

        singleIncomeWithProps.ALMACEN_FOR = "";
        if (singleIncomeWithProps.CLAVE_ALMACEN) {
          singleIncomeWithProps.ALMACEN_FOR =
            singleIncomeWithProps.CLAVE_ALMACEN.CLAVE_ALMACEN;
        }

        if (this.type !== 0) {
          if (
            singleIncomeWithProps.CLAVE_VENTA &&
            singleIncomeWithProps.CLAVE_VENTA.NOMBRE_ALMACEN_ORIGEN
          ) {
            singleIncomeWithProps.ALMACEN_FOR =
              singleIncomeWithProps.CLAVE_VENTA.NOMBRE_ALMACEN_ORIGEN;
          }

          if (
            singleIncomeWithProps.CLAVE_VENTA_SERVICIO &&
            singleIncomeWithProps.CLAVE_VENTA_SERVICIO.NOMBRE_ALMACEN_ORIGEN
          ) {
            singleIncomeWithProps.ALMACEN_FOR =
              singleIncomeWithProps.CLAVE_VENTA_SERVICIO.NOMBRE_ALMACEN_ORIGEN;
          }
        }

        singleIncomeWithProps.formatedCreationDate = moment(
          singleIncomeWithProps.FECHA
        ).format("DD-MM-YYYY HH:mm");
        singleIncomeWithProps.formatedExpirationDate = moment(
          singleIncomeWithProps.FECHA_VEN
        ).format("DD-MM-YYYY HH:mm");
        return singleIncomeWithProps;
      }).filter((singleIncome) => {
        return (
          singleIncome.TIPO_ING === "V" &&
          singleIncome.CLAVE_VENTA &&
          singleIncome.CLAVE_VENTA.STATUS_FAC === "TI"
        );
      });
    },
    filteredAccounts() {
      if (this.filterClient === "") {
        return this.clientsAccounts.map((singleAccount) => {
          let singleAccountWithProps = { ...singleAccount };

          singleAccountWithProps.CLIENTE_FOR =
            singleAccount.CLAVE_CLIENTE.NOMBRE_EMPRESA;

          return singleAccountWithProps;
        });
      }
      return this.clientsAccounts.filter((singleAccount) => {
        if (singleAccount.CLAVE_CLIENTE._id === this.filterClient._id) {
          return true;
        }
        return false;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
