<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Reportes contabilidad </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item label="Estado de cuenta clientes facturado" icon="note">
        <ClientsBalanceInvoices
          :visible="
            companyPaymentPlanModules.accounting.getClientsBalancesInvoices ===
            'S'
          "
        ></ClientsBalanceInvoices>
      </b-tab-item>
      <b-tab-item label="Facturas clientes" icon="cash">
        <ClientsAccountsInvoices
          :visible="
            companyPaymentPlanModules.accounting.getClientsAccountsInvoices ===
            'S'
          "
        ></ClientsAccountsInvoices>
      </b-tab-item>
      <b-tab-item label="Venta con pago sin facturar" icon="cash">
        <SalesNoPayment
          :visible="
            companyPaymentPlanModules.accounting.getSalesNoPayments === 'S'
          "
        ></SalesNoPayment>
      </b-tab-item>
      <b-tab-item label="Pagos a clientes" icon="bank-transfer-out">
        <ClientsAccountsPayments
          :visible="
            companyPaymentPlanModules.accounting.getClientsAccountsPayments ===
            'S'
          "
        ></ClientsAccountsPayments>
      </b-tab-item>
      <b-tab-item label="Estado de cuenta acreedores" icon="currency-usd-off">
        <AcreedoresBalanceInvoice
          :visible="
            companyPaymentPlanModules.accounting.getProvidersBalanceInvoices ===
            'S'
          "
        ></AcreedoresBalanceInvoice>
      </b-tab-item>
      <b-tab-item label="Estado de cuenta proveedores" icon="currency-usd-off">
        <ProvidersBalanceInvoice
          :visible="
            companyPaymentPlanModules.accounting.getProvidersBalanceInvoices ===
            'S'
          "
        ></ProvidersBalanceInvoice>
      </b-tab-item>
      <b-tab-item label="Detallles cuentas acreedores" icon="tools">
        <ProvidersAccountsDetails
          :type="0"
          :visible="
            companyPaymentPlanModules.accounting.getProvidersAccountsDetails ===
            'S'
          "
        ></ProvidersAccountsDetails>
      </b-tab-item>
      <b-tab-item label="Detallles cuentas proveedores" icon="tools">
        <ProvidersAccountsDetails
          :type="1"
          :visible="
            companyPaymentPlanModules.accounting.getProvidersAccountsDetails ===
            'S'
          "
        ></ProvidersAccountsDetails>
      </b-tab-item>
      <b-tab-item label="Pagos a acreedores" icon="bank-transfer-in">
        <ProvidersAccountsPayments
          :type="0"
          :visible="
            companyPaymentPlanModules.accounting
              .getProvidersAccountsPayments === 'S'
          "
        ></ProvidersAccountsPayments>
      </b-tab-item>
      <b-tab-item label="Pagos a proveedores" icon="bank-transfer-in">
        <ProvidersAccountsPayments
          :type="1"
          :visible="
            companyPaymentPlanModules.accounting
              .getProvidersAccountsPayments === 'S'
          "
        ></ProvidersAccountsPayments>
      </b-tab-item>
      <b-tab-item label="Facturas en efectivo PV" icon="cash">
        <CashSales
          :type="0"
          :visible="companyPaymentPlanModules.accounting.getCashSales === 'S'"
        ></CashSales>
      </b-tab-item>
      <b-tab-item label="Facturas en efectivo venta general" icon="cash">
        <CashSales
          :type="1"
          :visible="companyPaymentPlanModules.accounting.getCashSales === 'S'"
        ></CashSales>
      </b-tab-item>
      <b-tab-item label="Diferencia entre total venta y xml" icon="bank-plus">
        <SalesXMLDifferences
          :visible="
            companyPaymentPlanModules.accounting.getSalesXMLDifferences === 'S'
          "
        ></SalesXMLDifferences>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import ClientsAccountsPayments from "../components/ClientsAccountsPayments.vue";
import ClientsBalanceInvoices from "../components/ClientsBalanceInvoices.vue";
import ClientsAccountsInvoices from "../components/ClientsAccountsInvoices.vue";
import AcreedoresBalanceInvoice from "../components/AcreedoresBalanceInvoice.vue";
import ProvidersBalanceInvoice from "../components/ProvidersBalanceInvoice";
import ProvidersAccountsDetails from "../components/ProvidersAccountsDetails";
import ProvidersAccountsPayments from "../components/ProvidersAccountsPayments.vue";
import CashSales from "../components/CashSales.vue";
import SalesNoPayment from "../components/SalesNoPayment.vue";
import SalesXMLDifferences from "../components/SalesXMLDifferences.vue";

export default {
  name: "AccountingReports",
  components: {
    ClientsAccountsPayments,
    ClientsBalanceInvoices,
    ClientsAccountsInvoices,
    AcreedoresBalanceInvoice,
    ProvidersBalanceInvoice,
    ProvidersAccountsDetails,
    ProvidersAccountsPayments,
    CashSales,
    SalesNoPayment,
    SalesXMLDifferences,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "accountingReports");
  },
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
    allPromises.push(this.$store.dispatch("GETBANKS"));
    allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
    allPromises.push(
      this.$store.dispatch("GETSALES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(
      this.$store.dispatch("GETSALESXMLDIFFERENCES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
        warehouses: [],
      })
    );
    allPromises.push(
      this.$store.dispatch("GETEXPENSES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
      })
    );
    allPromises.push(
      this.$store.dispatch("GETINCOMES", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
      })
    );
    allPromises.push(
      this.$store.dispatch("GETPAYMENTS", {
        startDate: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 15
        ),
        endDate: new Date(),
      })
    );
    allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
    allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
    allPromises.push(this.$store.dispatch("GETLASTINCOMENUMBER"));

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return null;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
