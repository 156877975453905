A/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="startExport()"
          >Exportar detalles</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field label="Filtrar por proveedor" label-position="inside">
          <b-select
            placeholder="Filtrar por proveedor"
            icon="account-group-outline"
            v-model="filterProvider"
            class="global-lists-filters-control"
          >
            <option value="">Todos los provedores</option>
            <option
              v-for="provider in providers"
              :value="provider"
              :key="provider.NOMBRE_PRV"
            >
              {{ provider.NOMBRE_PRV }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de de detalles en cuentas por pagar:
      <b>{{ providersAccountsDetails.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="providersAccountsDetails"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedBrands"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.NUM_FOLIO}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="NUM_FOLIO"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">No hay cuentas por pagars</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
            :cell-class="
              props.row.DIAS_VEN > 0 && props.row.STATUS !== 'PA'
                ? 'red-cell'
                : ''
            "
          >
            <span
              v-if="!checkIfValid(props.row[column.field]) && !column.money"
              >{{ "Sin información" }}</span
            >
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <p>No hay información que mostrar</p>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import { openConfirmationModalDanger } from "@/utils/component-fns";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ProvidersAccountsDetails",
  props: ["type"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeValue: this.type,
      perPage: 50,
      checkedBrands: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "NUM_FOLIO",
          label: "Folio de cuenta por pagar",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PROV_FOR",
          label: "Proveedor o Acreedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ALMACEN_FOR",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FACTURA",
          label: "Factura",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FORMATED",
          label: "Estatus",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedInvoiceDate",
          label: "Fecha factura",
          sortable: true,
          searchable: true,
          display: false,
          customSort: this.sortByDateInvoice,
        },
        {
          field: "formatedCreationDate",
          label: "Fecha recepción",
          sortable: true,
          searchable: true,
          display: false,
          customSort: this.sortByDate,
        },
        {
          field: "CONCEP_IE",
          label: "Concepto",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIBEAR",
          label: "Descripción",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "CANTIDAD",
          label: "Cantidad",
          sortable: true,
          searchable: true,
          display: false,
        },
        {
          field: "TOTAL",
          label: "Total",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
        {
          field: "RESTA",
          label: "Restante a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
        },
      ],
      masks: Masks,
      brandRequiredErrors: {
        CLAVE_MARC: false,
        DESC_MARC: false,
      },
      filterProvider: "",
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      if (this.$store.getters.BANKMOVEMENTTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      }
      allPromises.push(
        this.$store.dispatch("GETEXPENSES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      if (this.$store.getters.PAYMENTTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
      }
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA).getTime() || -Infinity) -
          (new Date(a.FECHA).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA).getTime() || -Infinity) -
          (new Date(b.FECHA).getTime() || -Infinity)
        );
      }
    },
    sortByDateExpiration(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_VEN).getTime() || -Infinity) -
          (new Date(a.FECHA_VEN).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_VEN).getTime() || -Infinity) -
          (new Date(b.FECHA_VEN).getTime() || -Infinity)
        );
      }
    },
    sortByDateInvoice(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_FAC).getTime() || -Infinity) -
          (new Date(a.FECHA_FAC).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_FAC).getTime() || -Infinity) -
          (new Date(b.FECHA_FAC).getTime() || -Infinity)
        );
      }
    },
    sortByMisingQuantity(a, b, isAsc) {
      if (isAsc) {
        return Number(a.RESTA) - Number(b.RESTA);
      } else {
        return Number(b.RESTA) - Number(a.RESTA);
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETEXPENSES", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
          })
        );

        await Promise.all(allPromises);
      }
    },
    async startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación",
        message: `¿Desea confirmar la exportación de los detalles a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: async () => {
          this.exportData();
        },
      });
    },
    exportData() {
      let payload = {
        data: this.providersAccountsDetails.map((singleDetail) => {
          const singleDetailFormatted = {};

          singleDetailFormatted["NUM FOLIO"] = "";
          if (singleDetail.NUM_FOLIO) {
            singleDetailFormatted["NUM FOLIO"] = singleDetail.NUM_FOLIO;
          }

          singleDetailFormatted["PROVEEDOR"] = "";
          if (singleDetail.PROV_FOR) {
            singleDetailFormatted["PROVEEDOR"] = singleDetail.PROV_FOR;
          }

          singleDetailFormatted["ALMACEN"] = "";
          if (singleDetail.ALMACEN_FOR) {
            singleDetailFormatted["ALMACEN"] = singleDetail.ALMACEN_FOR;
          }

          singleDetailFormatted["FACTURA"] = "";
          if (singleDetail.FACTURA) {
            singleDetailFormatted["FACTURA"] = singleDetail.FACTURA;
          }

          singleDetailFormatted["ESTATUS"] = "";
          if (singleDetail.STATUS_FORMATED) {
            singleDetailFormatted["ESTATUS"] = singleDetail.STATUS_FORMATED;
          }

          singleDetailFormatted["FECHA"] = "";
          if (singleDetail.formatedInvoiceDate) {
            singleDetailFormatted["FECHA"] = singleDetail.formatedInvoiceDate;
          }

          singleDetailFormatted["FECHA CREACION"] = "";
          if (singleDetail.formatedCreationDate) {
            singleDetailFormatted["FECHA CREACION"] =
              singleDetail.formatedCreationDate;
          }

          singleDetailFormatted["CONCEPTO"] = "";
          if (singleDetail.CONCEP_IE) {
            singleDetailFormatted["CONCEPTO"] = singleDetail.CONCEP_IE;
          }

          singleDetailFormatted["DESCRIPCION"] = "";
          if (singleDetail.DESCRIBEAR) {
            singleDetailFormatted["DESCRIPCION"] = singleDetail.DESCRIBEAR;
          }

          singleDetailFormatted["CANTIDAD"] = "";
          if (singleDetail.CANTIDAD) {
            singleDetailFormatted["CANTIDAD"] = singleDetail.CANTIDAD;
          }

          singleDetailFormatted["TOTAL"] = "";
          if (singleDetail.TOTAL) {
            singleDetailFormatted["TOTAL"] = singleDetail.TOTAL;
          }

          singleDetailFormatted["RESTANTE A PAGAR"] = "";
          if (singleDetail.RESTA) {
            singleDetailFormatted["RESTANTE A PAGAR"] = singleDetail.RESTA;
          }

          return singleDetailFormatted;
        }),
        name: "detalles-proveedor" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        const providerWithProps = { ...singleProvider };

        return providerWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
      });
    },
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    providersAccounts() {
      return this.$store.getters.EXPENSES.map((singleExpense) => {
        let singleExpenseProps = { ...singleExpense };

        singleExpenseProps.PROV_FOR = singleExpense.CLAVE_PRV
          ? singleExpense.CLAVE_PRV.NOMBRE_PRV
          : "";

        singleExpenseProps.IVA_GTO = String(singleExpenseProps.IVA_GTO);
        singleExpenseProps.TOTAL_GTO = String(singleExpenseProps.TOTAL_GTO);
        singleExpenseProps.RESTA = String(singleExpenseProps.RESTA);

        if (singleExpenseProps.STATUS === "NA")
          singleExpenseProps.STATUS_FORMATED = "Pendiente autorización";
        if (singleExpenseProps.STATUS === "AC")
          singleExpenseProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleExpenseProps.STATUS === "PE")
          singleExpenseProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singleExpenseProps.STATUS === "PA")
          singleExpenseProps.STATUS_FORMATED = "Totalmente pagada";
        if (singleExpenseProps.STATUS === "CA") {
          singleExpenseProps.STATUS_FORMATED = "Cancelada";
          singleExpenseProps.RESTA = 0;
        }
        if (singleExpenseProps.STATUS === "VE")
          singleExpenseProps.STATUS_FORMATED = "Vencida";

        singleExpenseProps.ALMACEN_FOR = "";
        if (singleExpenseProps.CLAVE_ALMACEN) {
          singleExpenseProps.ALMACEN_FOR =
            singleExpenseProps.CLAVE_ALMACEN.CLAVE_ALMACEN;
        }

        singleExpenseProps.formatedInvoiceDate = moment(
          singleExpenseProps.FECHA_FAC
        ).format("DD-MM-YYYY HH:mm");
        singleExpenseProps.formatedCreationDate = moment(
          singleExpenseProps.FECHA
        ).format("DD-MM-YYYY HH:mm");
        singleExpenseProps.formatedExpirationDate = moment(
          singleExpenseProps.FECHA_VEN
        ).format("DD-MM-YYYY HH:mm");

        singleExpenseProps.RESTA = String(singleExpenseProps.RESTA);
        singleExpenseProps.TOTAL = String(singleExpenseProps.TOTAL);

        return singleExpenseProps;
      }).filter((singleExpense) => {
        return this.type === 0
          ? singleExpense.TIPO_EGR === "E"
          : singleExpense.TIPO_EGR === "C";
      });
    },
    filteredAccounts() {
      if (this.filterProvider === "") {
        return this.providersAccounts.map((singleAccount) => {
          return { ...singleAccount };
        });
      }
      return this.providersAccounts.filter((singleAccount) => {
        if (singleAccount.CLAVE_PRV._id === this.filterProvider._id) {
          return true;
        }
        return false;
      });
    },
    providersAccountsDetails() {
      let allProvidersAccountsDetails = [];

      for (const singleAccount of this.filteredAccounts) {
        if (singleAccount.GASTO_DET) {
          for (const singleDetail of singleAccount.GASTO_DET) {
            allProvidersAccountsDetails.push({
              NUM_FOLIO: singleAccount.NUM_FOLIO,
              PROV_FOR: singleAccount.PROV_FOR,
              ALMACEN_FOR: singleAccount.ALMACEN_FOR,
              FACTURA: singleAccount.FACTURA,
              STATUS_FORMATED: singleAccount.STATUS_FORMATED,
              formatedInvoiceDate: singleAccount.formatedInvoiceDate,
              formatedCreationDate: singleAccount.formatedCreationDate,
              CONCEP_IE: singleDetail.CONCEP_IE,
              DESCRIBEAR: singleDetail.DESCRIBEAR,
              CANTIDAD: singleDetail.CANTIDAD,
              TOTAL: singleDetail.TOTAL,
              RESTA: singleDetail.RESTA,
            });
          }
        }
      }

      return allProvidersAccountsDetails;
    },
    totalBalance() {
      return this.$store.getters.TOTALBALANCE;
    },
    totalBalanceCon() {
      return this.$store.getters.TOTALBALANCECON;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
