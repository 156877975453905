var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container global-list-main-container"},[_vm._m(0),_c('div',{staticClass:"upper-global-hint"},[_c('b-icon',{attrs:{"icon":"lightbulb-outline","type":"is-primary"}}),_c('span',{staticClass:"global-form-hint-color"},[_vm._v(" Recuerda: ")]),_vm._v(" "+_vm._s(_vm.hint)+" ")],1),_c('b-tabs',{attrs:{"type":"is-boxed"}},[_c('b-tab-item',{attrs:{"label":"Estado de cuenta clientes facturado","icon":"note"}},[_c('ClientsBalanceInvoices',{attrs:{"visible":_vm.companyPaymentPlanModules.accounting.getClientsBalancesInvoices ===
          'S'}})],1),_c('b-tab-item',{attrs:{"label":"Facturas clientes","icon":"cash"}},[_c('ClientsAccountsInvoices',{attrs:{"visible":_vm.companyPaymentPlanModules.accounting.getClientsAccountsInvoices ===
          'S'}})],1),_c('b-tab-item',{attrs:{"label":"Venta con pago sin facturar","icon":"cash"}},[_c('SalesNoPayment',{attrs:{"visible":_vm.companyPaymentPlanModules.accounting.getSalesNoPayments === 'S'}})],1),_c('b-tab-item',{attrs:{"label":"Pagos a clientes","icon":"bank-transfer-out"}},[_c('ClientsAccountsPayments',{attrs:{"visible":_vm.companyPaymentPlanModules.accounting.getClientsAccountsPayments ===
          'S'}})],1),_c('b-tab-item',{attrs:{"label":"Estado de cuenta acreedores","icon":"currency-usd-off"}},[_c('AcreedoresBalanceInvoice',{attrs:{"visible":_vm.companyPaymentPlanModules.accounting.getProvidersBalanceInvoices ===
          'S'}})],1),_c('b-tab-item',{attrs:{"label":"Estado de cuenta proveedores","icon":"currency-usd-off"}},[_c('ProvidersBalanceInvoice',{attrs:{"visible":_vm.companyPaymentPlanModules.accounting.getProvidersBalanceInvoices ===
          'S'}})],1),_c('b-tab-item',{attrs:{"label":"Detallles cuentas acreedores","icon":"tools"}},[_c('ProvidersAccountsDetails',{attrs:{"type":0,"visible":_vm.companyPaymentPlanModules.accounting.getProvidersAccountsDetails ===
          'S'}})],1),_c('b-tab-item',{attrs:{"label":"Detallles cuentas proveedores","icon":"tools"}},[_c('ProvidersAccountsDetails',{attrs:{"type":1,"visible":_vm.companyPaymentPlanModules.accounting.getProvidersAccountsDetails ===
          'S'}})],1),_c('b-tab-item',{attrs:{"label":"Pagos a acreedores","icon":"bank-transfer-in"}},[_c('ProvidersAccountsPayments',{attrs:{"type":0,"visible":_vm.companyPaymentPlanModules.accounting
            .getProvidersAccountsPayments === 'S'}})],1),_c('b-tab-item',{attrs:{"label":"Pagos a proveedores","icon":"bank-transfer-in"}},[_c('ProvidersAccountsPayments',{attrs:{"type":1,"visible":_vm.companyPaymentPlanModules.accounting
            .getProvidersAccountsPayments === 'S'}})],1),_c('b-tab-item',{attrs:{"label":"Facturas en efectivo PV","icon":"cash"}},[_c('CashSales',{attrs:{"type":0,"visible":_vm.companyPaymentPlanModules.accounting.getCashSales === 'S'}})],1),_c('b-tab-item',{attrs:{"label":"Facturas en efectivo venta general","icon":"cash"}},[_c('CashSales',{attrs:{"type":1,"visible":_vm.companyPaymentPlanModules.accounting.getCashSales === 'S'}})],1),_c('b-tab-item',{attrs:{"label":"Diferencia entre total venta y xml","icon":"bank-plus"}},[_c('SalesXMLDifferences',{attrs:{"visible":_vm.companyPaymentPlanModules.accounting.getSalesXMLDifferences === 'S'}})],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"upper-global-title"},[_c('span',{},[_vm._v(" Reportes contabilidad ")])])}]

export { render, staticRenderFns }