/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-button type="is-primary" @click="startExport()"
          >Exportar pagos</b-button
        >
      </div>
      <div class="control">
        <b-button
          type="is-primary"
          @click="reloadInformation()"
          :loading="refreshLoading"
          >Refrescar información</b-button
        >
      </div>
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-columns">
        <b-checkbox
          v-for="singleColumn in columns"
          v-bind:key="singleColumn.label"
          v-model="singleColumn.display"
        >
          {{ singleColumn.label }}
        </b-checkbox>
      </div>
      <div class="global-lists-filters-container">
        <b-field label="Filtrar por proveedor" label-position="inside">
          <b-select
            placeholder="Filtrar por proveedor"
            icon="account-group-outline"
            v-model="filterProvider"
            class="global-lists-filters-control"
          >
            <option value="">Todos los provedores</option>
            <option
              v-for="provider in providers"
              :value="provider"
              :key="provider.NOMBRE_PRV"
            >
              {{ provider.NOMBRE_PRV }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="global-lists-filters-container">
        <b-field
          label="Fecha de inicio"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.startDate }"
          :message="{
            'La fecha de inicio no es valida': errors.startDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de inicio"
            icon="calendar-today"
            v-model="startDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
        <b-field
          label="Fecha de fin"
          label-position="inside"
          class="no-margin"
          :type="{ 'is-danger': errors.endDate }"
          :message="{
            'La fecha de fin no es valida': errors.endDate,
          }"
        >
          <b-datepicker
            class="global-lists-filters-control"
            @input="(value) => changeDateFilterValue(value)"
            :date-formatter="(date) => moment(date).format('DD/MM/YYYY')"
            :day-names="['D', 'L', 'M', 'M', 'J', 'V', 'S']"
            :month-names="[
              'Enero',
              'Febrero',
              'Marzo',
              'Abril',
              'Mayo',
              'Junio',
              'Julio',
              'Agosto',
              'Septiembre',
              'Octubre',
              'Noviembre',
              'Diciembre',
            ]"
            :show-week-number="true"
            locale="es-MX"
            placeholder="Fecha de fin"
            icon="calendar-today"
            v-model="endDate"
            trap-focus
          >
          </b-datepicker>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de pagos:
      <b>{{ filteredPayments.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="filteredPayments"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Brands"
        :checked-rows.sync="checkedIncomes"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.NUM_FOLIO}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">No hay pagos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            v-for="column in columns"
            searchable
            sortable
            v-bind="column"
            :visible="column.display"
            :key="column.field"
            :label="column.label"
            :custom-sort="column.customSort"
            :cell-class="
              props.row.DIAS_VEN > 0 && props.row.STATUS !== 'PA'
                ? 'red-cell'
                : ''
            "
          >
            <span
              v-if="!checkIfValid(props.row[column.field]) && !column.money"
              >{{ "Sin información" }}</span
            >
            <span v-if="column.money">{{
              props.row[column.field] | money("MXN", 2)
            }}</span>
            <span v-if="!column.money">{{ props.row[column.field] }}</span>
          </b-table-column>
        </template>

        <template slot="detail">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <p>No hay información que mostrar</p>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};
// @ is an alias to /src
export default {
  name: "ProvidersAccountsPayments",
  props: ["type"],
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      checkedIncomes: [],
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      columns: [
        {
          field: "NUM_FOLIO",
          label: "Folio de pago",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "ALMACEN_FOR",
          label: "Almacen",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PROV_FOR",
          label: "Proveedor",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "DESCRIPCION_FOR",
          label: "Descripcion",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "FACTURAS_FOR",
          label: "Facturas de gasto",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "STATUS_FORMATED",
          label: "Estatus de cuenta",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "formatedPaymentDate",
          label: "Fecha de pago",
          sortable: true,
          searchable: true,
          display: true,
          customSort: this.sortByDate,
        },
        {
          field: "FORMA_PAGO_FOR",
          label: "Método de pago",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "REFERENCIA_MOVIMIENTO",
          label: "Referencia de pago",
          sortable: true,
          searchable: true,
          display: true,
        },
        {
          field: "PAGO_PARC",
          label: "Cantidad de pago",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByPayment,
        },
        {
          field: "IVA_VENTA",
          label: "IVA de gasto",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByMisingTotal,
        },
        {
          field: "IEPS_VENTA",
          label: "IEPS de gasto",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByMisingTotal,
        },
        {
          field: "TOTAL_VENTA",
          label: "Total de gasto",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByMisingTotal,
        },
        {
          field: "RESTANTE_PAGAR",
          label: "Restante a pagar",
          sortable: true,
          searchable: true,
          display: true,
          money: true,
          customSort: this.sortByMisingQuantity,
        },
      ],
      masks: Masks,
      filterProvider: "",
      moment: moment,
      startDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() - 15
      ),
      endDate: new Date(),
      errors: {
        startDate: false,
        endDate: false,
      },
      refreshLoading: false,
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    async reloadInformation() {
      this.refreshLoading = true;

      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      allPromises.push(
        this.$store.dispatch("GETEXPENSES", {
          startDate: this.startDate,
          endDate: new Date(
            new Date(this.endDate).getFullYear(),
            new Date(this.endDate).getMonth(),
            new Date(this.endDate).getDate() + 1
          ),
        })
      );
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));

      await Promise.all(allPromises);

      this.refreshLoading = false;
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_PAGO).getTime() || -Infinity) -
          (new Date(a.FECHA_PAGO).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_PAGO).getTime() || -Infinity) -
          (new Date(b.FECHA_PAGO).getTime() || -Infinity)
        );
      }
    },
    sortByMisingQuantity(a, b, isAsc) {
      if (isAsc && a.GASTO_ID && b.GASTO_ID) {
        return Number(a.GASTO_ID.RESTA) - Number(b.GASTO_ID.RESTA);
      } else {
        return Number(b.GASTO_ID.RESTA) - Number(a.GASTO_ID.RESTA);
      }
    },
    sortByMisingTotal(a, b, isAsc) {
      if (isAsc && a.GASTO_ID && b.GASTO_ID) {
        return Number(a.GASTO_ID.TOTAL_GTO) - Number(b.GASTO_ID.TOTAL_GTO);
      } else {
        return Number(b.GASTO_ID.TOTAL_GTO) - Number(a.GASTO_ID.TOTAL_GTO);
      }
    },
    sortByPayment(a, b, isAsc) {
      if (isAsc) {
        return Number(a.PAGO_PARC) - Number(b.PAGO_PARC);
      } else {
        return Number(b.PAGO_PARC) - Number(a.PAGO_PARC);
      }
    },
    async changeDateFilterValue() {
      if (moment(new Date(this.startDate)).isAfter(new Date(this.endDate))) {
        this.errors.starDate = true;
      } else {
        this.errors.starDate = false;
      }

      if (moment(new Date(this.endDate)).isBefore(new Date(this.starDate))) {
        this.errors.endDate = true;
      } else {
        this.errors.endDate = false;
      }

      if (!this.errors.startDate && !this.errors.endDate) {
        let allPromises = [];

        allPromises.push(
          this.$store.dispatch("GETPAYMENTS", {
            startDate: this.startDate,
            endDate: new Date(
              new Date(this.endDate).getFullYear(),
              new Date(this.endDate).getMonth(),
              new Date(this.endDate).getDate() + 1
            ),
          })
        );

        await Promise.all(allPromises);
      }
    },
    async startExport() {
      this.$buefy.dialog.confirm({
        title: "Confirmar exportación de pagos",
        message: `¿Desea confirmar la exportación de los pagos de gastos a un archivo csv?`,
        cancelText: "Cancelar",
        confirmText: "Confirmar",
        type: "is-success",
        onConfirm: async () => {
          this.exportData();
        },
      });
    },
    exportData() {
      let payload = {
        data: this.payments.map((singlePayment) => {
          const singlePaymentFormatted = {};

          singlePaymentFormatted["DESCRIPCION"] = "";
          if (singlePayment.GASTO_ID) {
            singlePaymentFormatted["DESCRIPCION"] =
              singlePayment.GASTO_ID.DESCRIPCION;
          }

          if (singlePayment.GASTO_ID && singlePayment.GASTO_ID.STATUS === "AC")
            singlePaymentFormatted["ESTATUS DE CUENTA"] = "Pendiente de pagar";
          if (singlePayment.GASTO_ID && singlePayment.GASTO_ID.STATUS === "PE")
            singlePaymentFormatted["ESTATUS DE CUENTA"] = "Pendiente de pagar";
          if (singlePayment.GASTO_ID && singlePayment.GASTO_ID.STATUS === "PA")
            singlePaymentFormatted["ESTATUS DE CUENTA"] = "Totalmente pagada";
          if (
            singlePayment.GASTO_ID &&
            singlePayment.GASTO_ID.STATUS === "CA"
          ) {
            singlePaymentFormatted["ESTATUS DE CUENTA"] = "Cancelada";
            singlePaymentFormatted.RESTA_PAGO = 0;
          }
          if (
            singlePayment.GASTO_ID &&
            singlePayment.GASTO_ID.STATUS === "NA"
          ) {
            singlePaymentFormatted["ESTATUS DE CUENTA"] =
              "Pendiente autorización";
          }
          if (
            singlePayment.GASTO_ID &&
            singlePayment.GASTO_ID.STATUS === "VE"
          ) {
            singlePaymentFormatted["ESTATUS DE CUENTA"] = "Vencida";
          }

          singlePaymentFormatted["ALMACEN"] = "";
          if (singlePayment.CLAVE_ALM) {
            singlePaymentFormatted["ALMACEN"] =
              singlePayment.CLAVE_ALM.NOMBRE_ALM;
          }

          singlePaymentFormatted["PROVEEDOR"] = "";
          if (singlePayment.CLAVE_PRV) {
            singlePaymentFormatted["PROVEEDOR"] =
              singlePayment.CLAVE_PRV.NOMBRE_PRV;
          }

          singlePaymentFormatted["MÉTODO DE PAGO"] = "";
          if (singlePayment.FORMA_P) {
            singlePaymentFormatted["MÉTODO DE PAGO"] =
              singlePayment.FORMA_P.DESCRIBE_P;
          }

          singlePaymentFormatted["FACTURA"] = "";

          if (singlePayment.GASTO_ID) {
            singlePaymentFormatted[
              "FACTURA"
            ] += `${singlePayment.GASTO_ID.FACTURA} `;
          }

          singlePaymentFormatted["FECHA DE PAGO"] = moment(
            singlePaymentFormatted.FECHA_PAGO
          ).format("DD-MM-YYYY HH:mm");

          singlePaymentFormatted["TOTAL DE GASTO"] = "0";
          if (singlePayment.GASTO_ID) {
            singlePaymentFormatted["TOTAL DE GASTO"] = String(
              singlePayment.GASTO_ID.TOTAL_GTO
            );
          }

          singlePaymentFormatted["IVA DE GASTO"] = "0";
          if (singlePayment.GASTO_ID) {
            singlePaymentFormatted["IVA DE GASTO"] = String(
              singlePayment.GASTO_ID.IVA_GTO
            );
          }

          singlePaymentFormatted["IEPS DE GASTO"] = "0";
          if (singlePayment.GASTO_ID) {
            singlePaymentFormatted["IEPS DE GASTO"] = String(
              singlePayment.GASTO_ID.IEPS_GTO
            );
          }

          singlePayment["RESTANTE A PAGAR"] = "0";
          if (singlePayment.GASTO_ID) {
            singlePayment["RESTANTE A PAGAR"] = String(
              singlePayment.GASTO_ID.RESTA
            );
          }

          singlePayment["CANTIDAD DE PAGO"] = String(singlePayment.PAGO_PARC);

          singlePaymentFormatted["REFERENCIA DE PAGO"] = "";
          if (singlePayment.CLAVE_MOVIMIENTO) {
            singlePaymentFormatted["REFERENCIA DE PAGO"] =
              singlePayment.REFERENCIA;
          }

          return singlePaymentFormatted;
        }),
        name: "pagos-gastos-" + moment().format("l").toString(),
      };
      this.$store.dispatch("EXPORTCSV", payload);
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    providers() {
      return this.$store.getters.PROVIDERS.map((singleProvider) => {
        const providerWithProps = { ...singleProvider };

        return providerWithProps;
      })
        .sort((a, b) => {
          return a.NOMBRE_PRV > b.NOMBRE_PRV ? 1 : -1;
        })
        .filter((singleProvider) => {
          return this.type === 0
            ? singleProvider.TIPO_PRV === "AC" ||
                singleProvider.TIPO_PRV === "PA"
            : singleProvider.TIPO_PRV === "PR" ||
                singleProvider.TIPO_PRV === "PA";
        });
    },
    payments() {
      return this.$store.getters.PAYMENTS.map((singlePayment) => {
        let singlePaymentWithProps = { ...singlePayment };

        singlePaymentWithProps.DESCRIPCION_FOR = "";
        if (singlePayment.GASTO_ID) {
          singlePaymentWithProps.DESCRIPCION_FOR =
            singlePayment.GASTO_ID.DESCRIPCION;
        }

        if (singlePayment.GASTO_ID && singlePayment.GASTO_ID.STATUS === "AC")
          singlePaymentWithProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singlePayment.GASTO_ID && singlePayment.GASTO_ID.STATUS === "PE")
          singlePaymentWithProps.STATUS_FORMATED = "Pendiente de pagar";
        if (singlePayment.GASTO_ID && singlePayment.GASTO_ID.STATUS === "PA")
          singlePaymentWithProps.STATUS_FORMATED = "Totalmente pagada";
        if (singlePayment.GASTO_ID && singlePayment.GASTO_ID.STATUS === "CA") {
          singlePaymentWithProps.STATUS_FORMATED = "Cancelada";
          singlePaymentWithProps.RESTA_PAGO = 0;
        }
        if (singlePayment.GASTO_ID && singlePayment.GASTO_ID.STATUS === "NA") {
          singlePaymentWithProps.STATUS_FORMATED = "Pendiente autorización";
        }

        singlePaymentWithProps.ALMACEN_FOR = "";
        if (singlePayment.CLAVE_ALM) {
          singlePaymentWithProps.ALMACEN_FOR =
            singlePayment.CLAVE_ALM.NOMBRE_ALM;
        }

        singlePaymentWithProps.PROV_FOR = "";
        if (singlePayment.CLAVE_PRV) {
          singlePaymentWithProps.PROV_FOR = singlePayment.CLAVE_PRV.NOMBRE_PRV;
        }

        singlePaymentWithProps.FORMA_PAGO_FOR = "";
        if (singlePayment.FORMA_P) {
          singlePaymentWithProps.FORMA_PAGO_FOR =
            singlePayment.FORMA_P.DESCRIBE_P;
        }

        singlePaymentWithProps.FACTURAS_FOR = "";
        if (singlePayment.GASTO_ID) {
          singlePaymentWithProps.FACTURAS_FOR += `${singlePayment.GASTO_ID.FACTURA} `;
        }

        singlePaymentWithProps.formatedPaymentDate = moment(
          singlePaymentWithProps.FECHA_PAGO
        ).format("DD-MM-YYYY HH:mm");

        singlePaymentWithProps.TOTAL_GASTO = "0";
        if (singlePayment.GASTO_ID) {
          singlePaymentWithProps.TOTAL_VENTA = String(
            singlePayment.GASTO_ID.TOTAL_GTO
          );
        }

        singlePaymentWithProps.IVA_GASTO = "0";
        if (singlePayment.GASTO_ID && singlePayment.GASTO_ID.IVA_GTO) {
          singlePaymentWithProps.IVA_GASTO = String(
            singlePayment.GASTO_ID.IVA_GTO
          );
        }

        singlePaymentWithProps.IEPS_GASTO = "0";
        if (singlePayment.GASTO_ID && singlePayment.GASTO_ID.CLAVE_VENTA) {
          singlePaymentWithProps.IEPS_GASTO = String(
            singlePayment.GASTO_ID.IEPS_GTO
          );
        }

        singlePayment.RESTANTE_PAGAR = "0";
        if (singlePayment.GASTO_ID) {
          singlePayment.RESTANTE_PAGAR = String(singlePayment.GASTO_ID.RESTA);
        }

        singlePayment.PAGO_PARC = String(singlePayment.PAGO_PARC);

        singlePaymentWithProps.REFERENCIA_MOVIMIENTO = "";
        if (singlePayment.CLAVE_MOVIMIENTO) {
          singlePaymentWithProps.REFERENCIA_MOVIMIENTO =
            singlePayment.REFERENCIA;
        }

        return singlePaymentWithProps;
      }).filter((singlePayment) => {
        return this.type === 0
          ? singlePayment.GASTO_ID && singlePayment.GASTO_ID.TIPO_EGR === "E"
          : singlePayment.GASTO_ID && singlePayment.GASTO_ID.TIPO_EGR === "C";
      });
    },
    filteredPayments() {
      if (this.filterProvider === "") {
        return this.payments.map((singlePayment) => {
          let singlePaymentWithProps = { ...singlePayment };

          return singlePaymentWithProps;
        });
      }
      return this.payments.filter((singlePayment) => {
        if (singlePayment.CLAVE_PRV._id === this.filterProvider._id) {
          return true;
        }
        return false;
      });
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>
